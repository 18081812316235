// import React, { useState } from "react";
// import PropTypes from "prop-types";
// import { ErrorMessage } from "components";
// import { motion } from "framer-motion";
// import { Select, SelectItem } from "@nextui-org/react";

// export const SelectBox = ({
//   className = "form-select",
//   value = "",
//   name = "",
//   id = "",
//   label = "",
//   autoFocus = false,
//   disabled = false,
//   changeHandler,
//   options = [],
//   required = false,
//   hidden,
//   errorMessage = "",
//   labelClass,
//   ThemeColor,
//   activity,
// }) => {
//   const [isFocused, setIsFocused] = useState(false);

//   return (
//     <>
//       <div className={`relative select_field_container`}>
//         <div className={`rounded w-full ${isFocused ? 'field-focused' : 'field-label'}`}>
//           <Select
//             id={id}
//             name={name}
//             label={label}
//             autoFocus={autoFocus}
//             isRequired={required}
//             selectedKeys={[value?.toString()]}
//             isDisabled={disabled}
//             selectionMode="single"
//             onKeyDown={() => setIsFocused(true)}
//             onFocus={() => setIsFocused(true)}
//             onChange={changeHandler}
//             onBlur={() => setIsFocused(false)}
//           >
//           {options.map((item) => (
//             <SelectItem key={item.value || item.id} value={item.value || item.id}>{item.name || item.label}</SelectItem>
//           ))}
//         </Select>
//       </div>
//     </div>
//        {errorMessage && (
//         <span>
//           <ErrorMessage message={errorMessage} />
//         </span>
//       )}
//     </>
//   );
// };

// SelectBox.propTypes = {
//   value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   name: PropTypes.string,
//   id: PropTypes.string,
//   className: PropTypes.string,
//   disabled: PropTypes.bool,
//   label: PropTypes.string,
//   required: PropTypes.bool,
//   changeHandler: PropTypes.func,
//   options: PropTypes.array.isRequired,
//   errorMessage: PropTypes.string,
// };




import React, { useState } from "react";
import PropTypes from "prop-types";
import { Select, SelectItem } from "@nextui-org/react";

export const SelectBox = ({
  className = "form-select",
  value = "",
  name = "",
  id = "",
  label = "",
  autoFocus = false,
  disabled = false,
  changeHandler,
  options = [],
  required = false,
  hidden,
  errorMessage = null, // Now optional (default: null)
  labelClass,
  ThemeColor,
  activity,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <>
      <div className={`relative select_field_container`}>
        <div
          className={`rounded w-full ${
            isFocused ? "field-focused" : "field-label"
          }`}
        >
          <Select
            id={id}
            name={name}
            label={label}
            autoFocus={autoFocus}
            isDisabled={disabled}
            selectionMode="single"
            selectedKeys={value ? new Set([value.toString()]) : new Set()} // Ensure correct selection
            onFocus={() => setIsFocused(true)}
            onChange={(e) => {
              changeHandler(e);
              setIsFocused(true); // Keep focus when changing
            }}
            onBlur={() => setIsFocused(false)}
            isRequired={false} // Disable NextUI built-in validation
          >
            {options.map((item) => (
              <SelectItem
                key={item.value || item.id}
                value={item.value || item.id}
              >
                {item.name || item.label}
              </SelectItem>
            ))}
          </Select>
        </div>
      </div>
      {/* Show error message only if it's provided */}
      {errorMessage && !value && (
        <span className="text-red-500 text-sm mt-1">{errorMessage}</span>
      )}
    </>
  );
};

SelectBox.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
  changeHandler: PropTypes.func,
  options: PropTypes.array.isRequired,
  errorMessage: PropTypes.string, // Optional
};
