import moment from "moment";


export const SelectBox = {
  id: "",
  key: "",
  fieldType: "select",
  isRequired: true,
  isUpdate: false,
  label: "Select Box",
  name: "",
  xmlName: "",
  popupText: "",
  placeHolder: "Please Select",
  helpNote: "",
  value: "",
  regex: "",
  hidden: true,
  options: [],
};

export const CreateNewProgram = {
  submit_url: "",
  form: [
    {
      id: "1",
      fieldType: "select",
      isRequired: true,
      isUpdate: false,
      label: "Organization",
      name: "organizationId",
      xmlName: "organizationId",
      popupText: "",
      helpNote: "",
      placeHolder: "Select Organization",
      errorMessage: "",
      value: "",
      regex: "",
      autoFocus: true,
      options: [
        {
          label: "Please select Organization",
          value: "",
        },
      ],
    },
    {
      id: "2",
      fieldType: "text",
      isRequired: true,
      isUpdate: true,
      name: "programName",
      label: "Program Name",
      xmlName: "programName",
      popupText: "",
      helpNote: "",
      placeHolder: "Program name",
      errorMessage: "Enter valid Program Name",
      regex: "",
      value: "",
      validators: {
        required: true,
        minLength: 30,
        pattern: "",
      },
    },
    {
      id: "3",
      fieldType: "date",
      isRequired: true,
      isUpdate: true,
      name: "programStartDate",
      label: "Start Date",
      xmlName: "programStartDate",
      popupText: "",
      helpNote: "",
      placeHolder: "Program Start Date",
      errorMessage: "Enter valid Program Start Date",
      regex: "",
      value: "",
      validators: {
        required: true,
        pattern: "",
      },
    },
    {
      id: "4",
      fieldType: "date",
      isRequired: true,
      isUpdate: true,
      name: "programEndDate",
      label: "End Date",
      xmlName: "programEndDate",
      popupText: "",
      helpNote: "",
      placeHolder: "Program End Date",
      errorMessage: "Enter valid Program End Date",
      regex: "",
      value: "",
      hidden: true,
      validators: {
        required: true,
        pattern: "",
      },
    },
    {
      id: "5",
      fieldType: "textarea",
      isRequired: true,
      isUpdate: true,
      name: "programDescription",
      label: "Description",
      xmlName: "programDescription",
      popupText: "",
      helpNote: "",
      placeHolder: "Add description here",
      errorMessage: "Enter description",
      regex: "",
      value: "",
      cols: "73",
      rows: "8",
      validators: {
        pattern: "",
        required: true,
        minLength: 300,
      },
    },
    // {
    //   id: "6",
    //   fieldType: "switch",
    //   isRequired: false,
    //   isUpdate: true,
    //   hidden: false,
    //   name: "status",
    //   label: "Status",
    //   xmlName: "status",
    //   popupText: "",
    //   helpNote: "",
    //   placeHolder: "",
    //   errorMessage: "Field Required !",
    //   value: true,
    // },
  ],
};

export const Columns = [
  {
    title: "programName",
    dataIndex: "programName",
    key: "programName",
  },
  {
    title: "programDescription",
    dataIndex: "programDescription",
    key: "programDescription",
  },
  {
    title: "status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "created",
    dataIndex: "createdDate",
    key: "createdDate",
    className: 'custom-header',
    render: (row) => moment(row).format("DD-MM-YY"),
  },
];
export const activityColumns = [
  {
    title: "Activity Name",
    dataIndex: "activityName",
    key: "activityName",
  },
  {
    title: "Activity Description",
    dataIndex: "activityDescription",
    key: "activityDescription",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Created",
    dataIndex: "createdDate",
    key: "createdDate",
    className: 'custom-header',
    render: (row) => moment(row).format("DD-MM-YY"),
  },
];

export const CreateNewActivity = {
  submit_url: "",
  form: [
    {
      id: "1",
      fieldType: "textBox",
      isRequired: true,
      isUpdate: false,
      label: "Activity Name",
      name: "formName",
      xmlName: "formName",
      popupText: "",
      helpNote: "",
      placeHolder: "E.g Engine Oil Data Collection Form",
      errorMessage: "Enter Valid Activity Name",
      value: "",
      regex: "",
      validators: {
        required: true,
        pattern: "",
      },
    },
    {
      id: "2",
      fieldType: "textBox",
      isRequired: true,
      isUpdate: true,
      name: "formShortDescription",
      label: "Activity Short Description",
      xmlName: "formShortDescription",
      popupText: "",
      helpNote: "",
      placeHolder: "Enter form short description",
      errorMessage: "Enter Valid form description",
      regex: "",
      value: "",
      validators: {
        required: true,
        pattern: "",
      },
    },
    {
      id: "3",
      fieldType: "textBox",
      isRequired: true,
      isUpdate: true,
      name: "formLongDescription",
      label: "Activity Long Description",
      xmlName: "formLongDescription",
      popupText: "",
      helpNote: "",
      placeHolder: "Enter form long description",
      errorMessage: "Enter Valid form description",
      regex: "",
      value: "",
      validators: {
        required: true,
        pattern: "",
      },
    },
    {
      id: "12",
      fieldType: "selectBox",
      isRequired: true,
      isUpdate: false,
      label: "Category",
      name: "categoryId",
      xmlName: "categoryId",
      popupText: "",
      helpNote: "",
      placeHolder: "Select category ",
      errorMessage: "",
      value: "",
      regex: "",
      options: [
        {
          label: "Please select category",
          value: "",
        },
      ],
    },
    {
      id: "6",
      fieldType: "selectBox",
      isRequired: true,
      isUpdate: false,
      label: "Sub Category",
      name: "subCategoryId",
      xmlName: "subCategoryId",
      popupText: "",
      helpNote: "",
      placeHolder: "Select sub category ",
      errorMessage: "",
      value: "",
      regex: "",
      options: [
        {
          label: "Please Select Sub Category",
          value: "",
        },
      ],
    },
    {
      id: "7",
      fieldType: "MDDLBOX", // MMDL
      isRequired: true,
      isUpdate: false,
      label: "Select Product",
      name: "productId",
      xmlName: "productId",
      custom: true,
      multiple: true,
      popupText: "",
      helpNote: "",
      placeHolder: "Select product",
      errorMessage: "",
      value: "",
      regex: "",
      options: [
        {
          label: "Please Select Product",
          value: "",
        },
      ],
    },
    {
      id: "13",
      fieldType: "selectBox",
      isRequired: true,
      isUpdate: false,
      label: "Form Type",
      name: "formType",
      xmlName: "formType",
      popupText: "",
      helpNote: "",
      placeHolder: "Select Form Type ",
      errorMessage: "",
      value: "",
      regex: "",
      options: [
        {
          label: "Please select Form Type",
          value: "",
        },
        {
          label: "Activity Form",
          value: "activity",
        },
        {
          label: "Pricing Form",
          value: "pricing",
        },
      ],
    },
    {
      id: "8",
      fieldType: "dateInput",
      isRequired: true,
      isUpdate: true,
      name: "formStartDate",
      label: "Start Date",
      xmlName: "formStartDate",
      popupText: "",
      helpNote: "",
      placeHolder: "Please select start date",
      errorMessage: "Enter valid Form Start Date",
      regex: "",
      value: "",
      validators: {
        required: true,
        pattern: "",
      },
    },
    {
      id: "9",
      fieldType: "dateInput",
      isRequired: true,
      isUpdate: true,
      name: "formEndDate",
      label: "End Date",
      xmlName: "formEndDate",
      popupText: "",
      helpNote: "",
      placeHolder: "Please select end date",
      errorMessage: "Enter valid Form End Date",
      regex: "",
      value: "",
      validators: {
        required: true,
        pattern: "",
      },
    },
    {
      id: "10",
      fieldType: "textBox",
      isRequired: false,
      isUpdate: true,
      name: "target",
      label: "Target",
      xmlName: "target",
      popupText: "",
      helpNote: "",
      placeHolder: "Please Enter Target",
      errorMessage: "Enter Valid Target Number Allowed Only",
      regex: "^[0-9]*$",
      value: "",
      validators: {
        required: true,
        pattern: "",
      },
    },
    {
      id: "11",
      fieldType: "selectBox",
      isRequired: false,
      isUpdate: false,
      label: "Select Game",
      name: "gameId",
      xmlName: "gameId",
      popupText: "",
      helpNote: "",
      placeHolder: "Select game ",
      errorMessage: "",
      value: "",
      regex: "",
      options: [
        {
          label: "Please Select Game",
          value: "",
        },
      ],
    },
  ],
};

export const CreateNewGameActivity = {
  submit_url: "",
  form: [
    {
      id: "1",
      fieldType: "textBox",
      isRequired: true,
      isUpdate: false,
      label: "Activity Game Name",
      name: "formName",
      xmlName: "formName",
      popupText: "",
      helpNote: "",
      placeHolder: "E.g Engine Oil Data Collection Form",
      errorMessage: "Enter Valid Activity Name",
      value: "",
      regex: "",
      validators: {
        required: true,
        pattern: "",
      },
    },
    {
      id: "2",
      fieldType: "textBox",
      isRequired: true,
      isUpdate: true,
      name: "formShortDescription",
      label: "Activity Game Short Description",
      xmlName: "formShortDescription",
      popupText: "",
      helpNote: "",
      placeHolder: "Enter form short description",
      errorMessage: "Enter Valid form description",
      regex: "",
      value: "",
      validators: {
        required: true,
        pattern: "",
      },
    },
    {
      id: "3",
      fieldType: "textBox",
      isRequired: true,
      isUpdate: true,
      name: "formLongDescription",
      label: "Activity Game Long Description",
      xmlName: "formLongDescription",
      popupText: "",
      helpNote: "",
      placeHolder: "Enter form long description",
      errorMessage: "Enter Valid form description",
      regex: "",
      value: "",
      validators: {
        required: true,
        pattern: "",
      },
    },
    {
      id: "12",
      fieldType: "selectBox",
      isRequired: true,
      isUpdate: false,
      label: "Category",
      name: "categoryId",
      xmlName: "categoryId",
      popupText: "",
      helpNote: "",
      placeHolder: "Select category ",
      errorMessage: "",
      value: "",
      regex: "",
      options: [
        {
          label: "Please select category",
          value: "",
        },
      ],
    },
    {
      id: "6",
      fieldType: "selectBox",
      isRequired: true,
      isUpdate: false,
      label: "Sub Category",
      name: "subCategoryId",
      xmlName: "subCategoryId",
      popupText: "",
      helpNote: "",
      placeHolder: "Select sub category ",
      errorMessage: "",
      value: "",
      regex: "",
      options: [
        {
          label: "Please Select Sub Category",
          value: "",
        },
      ],
    },
    {
      id: "7",
      fieldType: "selectBox",
      isRequired: true,
      isUpdate: false,
      label: "Select Product",
      name: "productId",
      xmlName: "productId",
      popupText: "",
      helpNote: "",
      placeHolder: "Select Product",
      errorMessage: "",
      value: "",
      regex: "",
      options: [
        {
          label: "Please Select Product",
          value: "",
        },
      ],
    },

    {
      id: "8",
      fieldType: "dateInput",
      isRequired: true,
      isUpdate: true,
      name: "formStartDate",
      label: "Start Date",
      xmlName: "formStartDate",
      popupText: "",
      helpNote: "",
      placeHolder: "Please select start date",
      errorMessage: "Enter valid Form Start Date",
      regex: "",
      value: "",
      validators: {
        required: true,
        pattern: "",
      },
    },
    {
      id: "9",
      fieldType: "dateInput",
      isRequired: true,
      isUpdate: true,
      name: "formEndDate",
      label: "End Date",
      xmlName: "formEndDate",
      popupText: "",
      helpNote: "",
      placeHolder: "Please select end date",
      errorMessage: "Enter valid Form End Date",
      regex: "",
      value: "",
      validators: {
        required: true,
        pattern: "",
      },
    },
    {
      id: "11",
      fieldType: "selectBox",
      isRequired: true,
      isUpdate: false,
      label: "Select Game",
      name: "gameId",
      xmlName: "gameId",
      popupText: "",
      helpNote: "",
      placeHolder: "Select game ",
      errorMessage: "",
      value: "",
      regex: "",
      options: [
        {
          label: "Please Select Game",
          value: "",
        },
      ],
      validators: {
        required: true,
        pattern: "",
      },
    },
  ],
};

export const CreateActivityJson = {
  submit_url: "",
  form: [
    {
      id: "1",
      fieldType: "text",
      isRequired: true,
      isUpdate: false,
      label: "Activity name",
      name: "activityName",
      xmlName: "activityName",
      autoFocus: true,
      popupText: "",
      helpNote: "",
      placeHolder: "Activity name",
      errorMessage: "",
      value: "",
      regex: "",
    },
    {
      id: "1",
      fieldType: "number",
      isRequired: true,
      isUpdate: false,
      label: "Activity Target",
      name: "target",
      xmlName: "target",
      autoFocus: true,
      popupText: "",
      helpNote: "",
      placeHolder: "Activity Target",
      errorMessage: "",
      value: "",
      regex: "",
    },
    {
      id: "3",
      fieldType: "select",
      isRequired: true,
      isUpdate: true,
      name: "activityTypeId",
      label: "Activity type",
      xmlName: "activityTypeId",
      popupText: "",
      helpNote: "",
      placeHolder: "activityTypeId",
      errorMessage: "Enter valid activityTypeId",
      regex: "",
      value: "",
      options: [
        {
          label: "Please select Activity Type",
          value: "",
        },
      ],
    },
    {
      id: "3",
      fieldType: "date",
      isRequired: true,
      isUpdate: true,
      name: "activityStartDate",
      label: "Start Date",
      xmlName: "activityStartDate",
      popupText: "",
      helpNote: "",
      placeHolder: "Activity Start Date",
      errorMessage: "Enter valid Activity Start Date",
      regex: "",
      value: "",
      validators: {
        required: true,
        pattern: "",
      },
    },
    {
      id: "4",
      fieldType: "date",
      isRequired: true,
      isUpdate: true,
      name: "activityEndDate",
      label: "End Date",
      xmlName: "activityEndDate",
      popupText: "",
      helpNote: "",
      placeHolder: "Activity End Date",
      errorMessage: "Enter valid Activity End Date",
      regex: "",
      value: "",
      hidden: true,
      validators: {
        required: true,
        pattern: "",
      },
    },
    {
      id: "3",
      fieldType: "select",
      isRequired: true,
      isUpdate: true,
      name: "agencyId",
      label: "Agency",
      xmlName: "agencyId",
      popupText: "",
      helpNote: "",
      placeHolder: "agencyId",
      errorMessage: "Enter valid agencyId",
      regex: "",
      value: "",
      options: [
        {
          label: "Please Select Agency",
          value: "",
        },
      ],
    },
    {
      id: "5",
      fieldType: "textarea",
      isRequired: true,
      isUpdate: true,
      name: "activityDescription",
      label: "Description",
      xmlName: "activityDescription",
      popupText: "",
      helpNote: "",
      placeHolder: "Add description here",
      errorMessage: "Enter description",
      regex: "",
      value: "",
      cols: "73",
      rows: "8",
      validators: {
        pattern: "",
        required: true,
        minLength: 300,
      },
    },
    {
      id: "6",
      fieldType: "switch",
      isRequired: false,
      isUpdate: true,
      hidden: true,
      name: "status",
      label: "Status",
      xmlName: "status",
      popupText: "",
      helpNote: "",
      placeHolder: "",
      errorMessage: "Field Required !",
      value: true,
    },
  ],
};

export const translateColumns = (arr, t) => {
  return arr?.map(item => {
    return { ...item, title: t(`tableHeaders.${item.title}`) }
  })
}

export const activityType = {
  DTD: 1, // Door to door activity
  IN_STORE: 2, // In store activity
  OUT_OF_HOME: 3 // out of home
}

export const activityFormPricingType = {
  pricing: "pricing",
  activity: "form",
  game: "game",
  pricingGame: "pricing + game"
}

export const activityFormFieldType = {
  product: "product"
}